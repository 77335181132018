@import 'variables.scss';

.emphasis {
  &.top {
    padding-top: $emphasis-padding;
  }

  &.bottom {
    padding-bottom: $emphasis-padding;
  }

  &.top-bottom {
    padding-top: $emphasis-padding;
    padding-bottom: $emphasis-padding;
  }

  &.all {
    padding: $emphasis-padding;
  }
}

.padded {
  &.top {
    padding-top: $base-padding;
  }

  &.bottom {
    padding-bottom: $base-padding;
  }

  &.top-bottom {
    padding-top: $base-padding;
    padding-bottom: $base-padding;
  }

  &.all {
    padding: $base-padding;
  }
}