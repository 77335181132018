/* --------------------
 * Main app stylesheet.
 * -------------------- */
@import 'variables.scss';
@import 'links.scss';
@import 'fonts.scss';
@import 'layout.scss';

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}