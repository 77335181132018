/* --------------
 * Font variables
 * -------------- */
$base-font-size: 1em;
$base-font-weight: 400;
$font-color: #121314;
$highlight-color: rgba(79, 193, 141, 0.5);
/* ----------------
 * Layout variables
 * ---------------- */
$base-padding: 1em;
$emphasis-padding: 3em;