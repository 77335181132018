.title {
  max-width: 40em;
}

.blurb {
  max-width: 30em;
}

.post-body {
  max-width: 50em;
}

.post-image {
  max-width: 25em;
  margin: auto;
}
