@import 'variables.scss';

body {
  color: $font-color;
  font-weight: $base-font-weight;
  font-size: $base-font-size;
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
p {
  padding: 0;
  margin: 0;
}

h1 {
  font-size: $base-font-size * 3;
  font-weight: $base-font-weight * 2.2;
}

h2 {
  font-size: $base-font-size * 2;
  font-weight: $base-font-weight * 2;
}

h3 {
  font-size: $base-font-size * 1.2;
  font-weight: $base-font-weight * 2.5;
}

h4 {
  font-size: $base-font-size * 1.5;
  font-weight: $base-font-weight;
  line-height: 1.3;
}